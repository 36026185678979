import React, {Component} from 'react';
import LoadingReviews from './LoadingReviews';

import 'datatables.net';
import 'datatables.net-bs4';

class Reviews extends Component {

constructor() {
    super()

    if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }

    this.state = {
      count: [],
      star: [],
      rate: []
    }
  }

componentDidMount () {

    const scriptReviews = document.createElement("script");
    scriptReviews.src = process.env.REACT_APP_API_URL +"/review/jscript";
    scriptReviews.async = false;
    scriptReviews.setAttribute("id",'scriptReviews');
    document.body.appendChild(scriptReviews);

    fetch(process.env.REACT_APP_API_URL+`/review/count`)
    .then(response => response.json())
    .then((json) => {
      this.setState({
        count: json.count,
        star: json.star,
        rate: json.rate
      })
    })
}

componentWillUnmount()
  {
    document.getElementById("scriptReviews").remove();
  }
  
  render(){
    return(
<section id="review" style={{ backgroundColor: "#ffffff" }}>
<div className="container mb-6">
  <div className="row">
      <div className="col-lg-8 col-md-10 mx-auto">
        <div className="col-lg-12 text-center">
          <h3 className="section-heading" style={{ marginTop: "50px" }}>How Our New Friend Talk About The Tour</h3>
          <strong> Rating :</strong>
          <span className="text-warning">
              &nbsp;
              <span dangerouslySetInnerHTML={ {__html: this.state.star} } />
              &nbsp;
              <span className="text-secondary" itemProp="ratingValue">{this.state.rate}</span>
          </span>
          <br />
          <small className="form-text text-muted">Based on <span itemProp="reviewCount">{this.state.count}</span> our new friend reviews</small>
          <hr className="hr-theme" />
        </div>

        <LoadingReviews />
        
        <table id="dataTables-example" style={{ width: "100%" }}>
          <tbody>           
          </tbody>
      </table>
    
    </div>
   </div>
</div>
<div style={{ height: "50px"}}></div>
</section>
      )
  }
}

export default Reviews;