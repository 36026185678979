import React, {Component} from 'react';

class Services extends Component {

	render(){
		return(
				<section className="page-section bg-light" id="services">
    <div className="container">
      <div style={{ height: "25px"}}></div>
        <div className="row">

          {JSON.parse(localStorage.getItem('services')).map((service,service_index) => {
                            
                            return (
                                    <div key={service_index} className="col-lg-3 col-md-6 text-center">
                                        <div className="mt-5 mb-2 ml-2 mr-2">
                                          <div dangerouslySetInnerHTML={{__html: service.icon}} />
                                          <h3 className="h4 mb-2">{service.name}</h3>
                                          <p className="text-muted mb-0">{service.description}</p>
                                        </div>
                                    </div>
                                )

          })}
          
        </div>
        <div style={{ height: "45px"}}></div>  
  </div>
</section>
			)
	}
}

export default Services;