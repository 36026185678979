import React, {Component} from 'react';
import Services from '../Services/Services';
import Header from '../Header/Header';
import Featured from '../Featured/Featured';
import Category from '../Category/Categories';

class Home extends Component {

	
	render(){
		return(
				<div>
					<Header />
					<Services />
					<Featured />
					<Category />
				</div>
			)
	}
}

export default Home;