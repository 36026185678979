import React, {Component} from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Redirect } from 'react-router-dom';
import '../../assets/css/activity-time-selector.css';
import uuid from 'react-uuid';


class Booking extends Component {
  
  constructor() {
    super()

     if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }
    
    this.state = {
      loadBokun: '',
      redirect: ''
    }
    if (localStorage.getItem('sessionId') === null) {
      localStorage.setItem('sessionId', uuid());
    }
    const openAppRoute = (urlRedirect) => {
      this.setState({
        redirect: urlRedirect
      });
    };
    window.openAppRoute = openAppRoute;

    const reloadJscript = () => {
      this.fetchJScript();
    };
    window.reloadJscript = reloadJscript;
  }

  fetchJScript()
  {
    
    const sessionId = localStorage.getItem('sessionId');

    const scriptProduct = document.createElement("script");
    scriptProduct.src = process.env.REACT_APP_API_URL +"/product/"+ this.props.match.params.slug +"/"+sessionId+"/product_jscript?embedded=false";
    scriptProduct.async = true;
    scriptProduct.setAttribute("id",'scriptProduct');
    scriptProduct.onload = function(e)
    {
      const scriptBokun = document.createElement("script");
      scriptBokun.src = localStorage.getItem('assets') +"/js/App.js";
      scriptBokun.async = true;
      scriptBokun.setAttribute("id",'scriptBokun');
      document.body.appendChild(scriptBokun);
    }
    document.body.appendChild(scriptProduct);

    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchJScript();
  }

  componentWillUnmount()
  {
    this.unmountScript();
  }

  unmountScript()
  {
    try
    {
      document.getElementById("scriptBokun").remove();
    }
    catch(error)
    {

    }

    try
    {
      document.getElementById("scriptProduct").remove();
    }
    catch(error)
    {
      
    }

  }

  render(){
  
  if(this.state.redirect!==""){
      let redirectUrl = this.state.redirect;
      this.setState({
        redirect: ''
      })
      return <Redirect to={redirectUrl} />
  }

  return (
    <section id="page" style={{ backgroundColor: "#ffffff" }}>
    <div className="container">
    <HelmetProvider>
    <Helmet>

        <title>{localStorage.getItem('siteTitle')}</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content={localStorage.getItem('siteTitle')} />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={localStorage.getItem('siteTitle')}/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
        
    </Helmet>
    </HelmetProvider>
    <div className="row">
      <div className="col-lg-12 col-md-12 mx-auto">
        <div className="row" style={{ paddingBottom: "0px" }}>
          <div className="col-lg-12 text-left">
            <div style={{ height: "77px" }} />
                <div className="card mb-8 shadow p-2">
                <div className="card-header">
                  <h3><i className="fa-solid fa-ticket"></i> Book <span id="titleProduct" /></h3>
                    Secure booking — only takes 2 minutes!
                </div>
                <div id="bookingframe" className="card-body" style={{ paddingLeft: "1px", paddingRight: "1px", paddingTop: "20px", paddingBottom: "15px" }}>
                  <div className="widget-body" id="WidgetContent">
                    <div className="widget">
                      <div id="proses">
            <div id="placeholder">
                  <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-2 big"></div>
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-2 big"></div>
                            </div>
                            <div className="ph-picture"></div>
                            <div className="ph-row">
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-4"></div>
                            </div>
                        </div>
                        
                        
                  </div>
                  <div className="ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-6 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                        </div>
                  </div>
                  <div className="ph-item">
                        
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-6 big"></div>
                                <div className="ph-col-6 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                            <div className="ph-row">
                                <div className="ph-col-4"></div>
                                <div className="ph-col-8 empty"></div>
                            </div>
                        </div>
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-8 empty"></div>
                                <div className="ph-col-4 big"></div>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
                      <div id="ActivityBookingWidget" />
                    </div>
                  </div>
                </div>
                
            </div>

            <div style={{ height: "50px" }} />  
          </div>
        </div>
      </div>
    </div>
    </div>
    </section>
    	)
  }
}

export default Booking;