import React, { Component } from 'react';


class Header extends Component {

componentDidMount () {
    
}

componentWillUnmount()
  {
    //document.getElementById("scriptScroll").remove();
  }

render(){
    
		return(
<header id="page-top" className="intro-header" style={{ backgroundImage: `url(${localStorage.getItem('headerBackground')})`, backgroundColor: "#000000"}}>
  <div className="col-lg-8 col-md-10 mx-auto">
    <div className="site-heading text-center ">
      <div className="transbox" style={{ minHeight: "100px", paddingTop: "35px", paddingBottom: "35px", paddingLeft: "10px", paddingRight: "10px" }}>
        
        <div dangerouslySetInnerHTML={ {__html:localStorage.getItem('headerBox')  } } />

        
                
                
      </div>
            <i className="fa fa-angle-down animate__animated animate__fadeInDown animate__infinite" style={{ fontSize: "50px", color: "#FFFFFF", marginTop: "30px" }}></i>
    </div>
    </div>
</header>
			)
	}
}

export default Header;