import React, { Component } from 'react';


class LoadingReceipt extends Component {



  render(){
    
    return(
<section style={{ backgroundColor: "#ffffff" }}>

  <div className="container">
  <div className="row">
    <div className="col-lg-12 col-md-12 mx-auto">
      <div className="row" style={{ paddingBottom: "0px"}}>
        <div className="col-lg-12 text-left">
          <div style={{ height: "70px" }} />

          <div className="card mb-8 shadow p-2 mt-2">
            <div className="card-body" style={{ 
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "15px"
            }}>
              <div className="col-md-12  mx-auto text-left">
                
                  
                    <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-4 big"></div>
                          <div className="ph-col-4 empty"></div>
                          <div className="ph-col-4 empty"></div>
                          <div className="ph-col-6"></div>
                          <div className="ph-col-6 empty"></div>
                          <div className="ph-col-6"></div>
                          <div className="ph-col-6 empty"></div>
                        </div>
                      </div>
                    </div>
                  
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-lg-6 col-lg-auto mb-6">
              <div className="card shadow mt-4">
                <div className="card-header bg-dark text-white pb-1">
                  <h4><i className="fas fa-file-invoice"></i> Booking Detail</h4>
                </div>
                <div className="card-body">
                 
                    
                    <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>
                    
                    
                    
                     
                     
                </div>
              </div>

              

            </div>

            <div className="col-lg-6 col-lg-auto mb-6 mt-4">


              <div className="card shadow">
                <div className="card-header bg-dark text-white pb-1">
                  <h4><i className="fas fa-file"></i> Travel Documents</h4>
                </div>
                
                <div className="card-body">
                  
                    <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <div className="card shadow mt-4">
                <div className="card-header bg-dark text-white pb-1">
                  <h4><i className="fas fa-user-tie"></i> Customer Info</h4>
                </div>
                <div className="card-body">
                    
                    <div className="ph-item border-0 pl-0 pr-0 pb-0">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                          <div className="ph-col-12"></div>
                        </div>
                      </div>
                    </div>
                      

                </div>
              </div>
            </div> 
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div style={{ height: "50px" }} />
</div>

</section>
      )
  }
}

export default LoadingReceipt;