import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import { Navbar, Nav }  from "react-bootstrap";

class MenuBottom extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  

  render(){
    

    return (

<Navbar expand="lg" className="navbar bg-light navbar-light navbar-expand d-md-none d-lg-none d-xl-none fixed-bottom shadow">
<Nav className="navbar-nav nav-justified w-100 mb-4 mt-2">
  
  <NavLink as={Link} activeStyle={{
    color: "#f2f2f2",
    backgroundColor: "#000000"
  }} activeClassName="active" className="nav-link font-weight-bold" to={{ pathname: "/tours" }}>
    <i className="fas fa-atlas fa-lg fa-fw"></i>
    <span className="small d-block">Explore Tours</span>
  </NavLink>
  
  <NavLink as={Link} activeStyle={{
    color: "#f2f2f2",
    backgroundColor: "#000000"
  }} activeClassName="active" className="nav-link font-weight-bold" to={{ pathname: "/booking/checkout" }}>
    <i className="fas fa-shopping-cart fa-lg fa-fw"></i>
    <span className="small d-block">Shopping Cart</span>
  </NavLink>

  <NavLink as={Link} activeStyle={{
    color: "#f2f2f2",
    backgroundColor: "#000000"
  }} activeClassName="active" className="nav-link font-weight-bold" to={{ pathname: "/booking/lastorder" }}>
    <i className="fas fa-file-signature fa-lg fa-fw"></i>
    <span className="small d-block">My Orders</span>
  </NavLink>

 
  
</Nav>
</Navbar>

            )
  }
}

export default MenuBottom;
