import React, {Component} from 'react';

class EmptyCart extends Component {
  
  
  
  render(){
  
  

  return (
    <section id="booking" style={{ backgroundColor: "#ffffff" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 mx-auto">
            <div className="row" style={{ paddingBottom: "0px" }}>
              <div className="col-lg-12 text-left">
                <div style={{ height: "77px" }}></div>
                <div className="card mb-8 shadow">
                <div className="card-header bg-dark text-white pb-1">
                            <h4><i className="fas fa-shopping-cart"></i> Order Summary</h4>
                          </div>
                  <div className="card-body" style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "15px" }}>
                    <div className="text-right">
          
                    </div>
              
                    
          
                    <div className="row col-md-6  mx-auto d-flex justify-content-center">
                      <div className="textwidget" style={{ minHeight: "450px" }}>
                        <div style={{ height: "100px" }}></div>
                        <div className="d-flex justify-content-center">
                       
                               
                                  <i className="fas fa-shopping-cart fa-8x"></i>
                               
                               
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                         <h3>Your Shopping Cart is Empty</h3>
                        </div>
                                
                          <div style={{ height: "50px" }}></div>
                        </div>
                      </div>
                    </div>

                  </div>
                     
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "60px"}}></div>  
        </div>
      </section>
    	)
  }
}

export default EmptyCart;