import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoadingPage from './LoadingPage';


class Page extends Component {
  
  constructor() {
    super()

    if (localStorage.getItem('sessionId') === null) {
      window.location.reload(false);
    }

    this.state = {
      page: [],
      redirect: ''
    }
  }

  fetchApi()
  {
    
    
    const {slug} = this.props.match.params;
    fetch(process.env.REACT_APP_API_URL+`/page/${slug}`)
    .then(response => response.json())
    .then((json) => {
      this.setState({
        page: json.page
      });

    })
    .catch((e) => {
        
        this.setState({
          redirect: '/page/not/found'
        });

    })
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchApi();
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.match.params.slug !== prevProps.match.params.slug ) {
     window.scrollTo(0, 0);
     this.fetchApi();
    };
  };
  
  render(){
  
  if(this.state.redirect!==""){
      let redirectUrl = this.state.redirect;
      this.setState({
        redirect: ''
      })
      return <Redirect to={redirectUrl} />
    }

  if(this.state.page.length===0)
    {
      return <LoadingPage />
    }

  return (
    <section id="page" style={{ backgroundColor: "#ffffff" }}>

    {this.state.page.map((page, index) => {

      return (
      <div className="container" key="index">
        <HelmetProvider>
        <Helmet>
        <title>{ page.title }</title>
        <meta name="description" content={ localStorage.getItem('siteDescription') } />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content={ page.title } />
        <meta name="og:description" content={ localStorage.getItem('siteDescription') } />

        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={ page.title }/>
        <meta name="twitter:description" content={ localStorage.getItem('siteDescription') } />
        </Helmet>
        </HelmetProvider>
        <div className="row">
          <div className="col-lg-12 col-md-12 mx-auto">
            <div className="row" style={{ paddingBottom: "0px" }}>
              <div className="col-lg-12 text-left">
                <div style={{ height: "77px" }}></div>
                <div className="card mb-8 shadow p-2">
                  <div className="card-body" style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "15px" }}>
                    <div className="text-right">
          
                    </div>
              
                    <div className="row" style={{ paddingBottom: "0px" }}>
                      <div className="col-lg-12 text-center">
                        <h3 className="section-heading">{ page.title }</h3>
                        <hr style={{ maxWidth: "50px", borderColor: "#1D57C7", borderWidth: "3px" }} />
                      </div>
                    </div>
          
                    <div className="row col-md-8  mx-auto text-left">
                      <div className="textwidget" style={{ minHeight: "250px" }}>
                        <div style={{ height: "50px" }}></div>
                          <div dangerouslySetInnerHTML={ {__html: page.content} } />
                          <div style={{ height: "50px" }}></div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div style={{ height: "40px" }}></div>    
                </div>
              </div>
            </div>
          </div>
        </div>
        )})}
      </section>
    	)
  }
}

export default Page;